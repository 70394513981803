<template>
  <div>
    <b-card no-body class="mb-1">
      <b-card-header class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-3 variant="info"
          >Boleto Bancário</b-button
        >
      </b-card-header>
      <b-collapse
        v-if="invoices[0].line"
        id="accordion-3"
        accordion="my-accordion"
        role="tabpanel"
        class="pb-4"
      >
        <b-card-body>
          <b-row class="justify-content-center">
            <b-col md="10" class="d-flex flex-column bank-card">
              <span>{{ digitableLine }}</span>
              <div
                id="bank-ticket-popover"
                @click="$refs.bankTicketModal.open()"
                class="m-2"
                v-html="barcode"
              ></div>
              <b-popover
                target="bank-ticket-popover"
                title="Clique para ampliar"
                triggers="hover"
                variant="info"
              >
              </b-popover>
            </b-col>
          </b-row>
        </b-card-body>

        <b-button @click="create_pdf()" v-if="!freezeButton"
          >Imprimir Boleto</b-button
        >
        <b-button v-else disabled
          >Gerando o arquivo PDF, aguarde... <b-spinner small></b-spinner
        ></b-button>
      </b-collapse>
      <b-collapse
        v-else
        id="accordion-3"
        accordion="my-accordion"
        role="tabpanel"
      >
        <div class="py-4">
          <b-button @click="generate_bank_ticket()" v-if="!freezeButton"
            >Gerar Boleto</b-button
          >
          <!-- <b-button @click="generate_bank_ticket()" v-if="!freezeButton"
            >Gerar Boleto</b-button
          >
          <b-button v-else disabled
            >Gerando Boleto Aguarde... <b-spinner small></b-spinner
          ></b-button> -->
        </div>
      </b-collapse>
    </b-card>

    <BankTicketPdf ref="pdf" />

    <SweetModal ref="bankTicketModal" overlay-theme="dark" width="100%">
      <div
        style="margin-left: 100px; margin-right: 100px"
        v-html="barcode"
      ></div>
    </SweetModal>
    <SweetModal ref="createBankTicket" overlay-theme="dark" width="30%" hide-close-button blocking>
      <div v-if="!submited">
        <sweetalert-icon icon="loading" />
        <p class="text-muted">Aguarde...</p>
        <p class="text-muted">
          Estamos requisitando ao banco, a emissão do boleto
        </p>
      </div>
      <div v-else>
        <sweetalert-icon icon="success" />
        <p class="text-muted">Certo!</p>
        <p class="text-muted">
          Seu boleto esta sendo gerado e em alguns minutos você receberá em seu
          email
        </p>
      </div>
    </SweetModal>
  </div>
</template>


<script>
import Boleto from "boleto.js";

export default {
  data() {
    return {
      bank: "Inter",
      bank_gateway: "",
      freezeButton: false,
      submited: false,
    };
  },
  components: {
    BankTicketPdf: () => import("./BankTicketPdf.vue"),
  },
  computed: {
    clientData() {
      return this.$store.state.checkout.clientData;
    },
    invoiceId() {
      return this.$store.state.checkout.invoiceId;
    },
    unityData() {
      return this.$store.state.checkout.unityData[0];
    },
    invoices() {
      return this.clientData.invoice;
    },
    bankData() {
      return this.clientData.unityBankAccount[0];
    },
    financialClientData() {
      return this.clientData.financialClient;
    },
    address() {
      const address = this.clientData.financialClient.address;

      return address;
    },
    barcode() {
      try {
        return new Boleto(this.invoices[0].line).toSVG();
      } catch (e) {
        return "erro ao gerar código de barras";
      }
    },
    digitableLine() {
      try {
        return new Boleto(this.invoices[0].line).prettyNumber();
      } catch (e) {
        return "erro ao gerar linha digitável";
      }
    },
  },
  methods: {
    async create_pdf() {
      this.freezeButton = true;
      setTimeout(async () => {
        this.freezeButton = await this.$refs.pdf.to_pdf();
      }, 200);
    },
    async generate_bank_ticket() {
      this.freezeButton = true;

      const agency = this.unityData.agency;
      const agency_d = this.unityData.agency_d;
      const account = this.unityData.account;
      const account_d = this.unityData.account_d;

      let data = {
        application_id: this.invoices[0].application_id,
        unity_id: this.invoices[0].unity_id,
        // ref: "invoices",
        lot_id: "",
        total: 1,
        banco: this.unityData.bank_number, // era bank
        data: [],
      };

      this.invoices.forEach((invoice) => {
        data.total += 1;
        data.data.push({
          invoice_id: invoice.id,
          beneficiary_name: this.bankData.beneficiary_name,
          beneficiary_document: this.bankData.beneficiary_document.replace(
            /[^0-9]+/g,
            ""
          ),
          beneficiary_zipcode: this.unityData.code,
          beneficiary_address:
            this.unityData.street + "-" + this.unityData.number,
          beneficiary_neighborhood: this.unityData.district,
          beneficiary_city: this.unityData.city,
          beneficiary_state: this.unityData.state,
          payer_name: this.financialClientData.financial_name,
          payer_document: this.financialClientData.financial_cpf,
          payer_zipcode: this.address.zip_code,
          payer_address: `${this.address.address}, ${this.address.number}`,
          payer_neighborhood: this.address.district,
          payer_city: this.address.city,
          payer_state: this.address.state,

          /////////////

          // payer_number

          value: invoice.total,
          venciment_day: invoice.venciment_day,
          validity_date: invoice.validity,
          days_to_download: "",
          discount_code: "",
          discount_date: "",
          discount_value: "",
          fee_date: "",
          fee_code: "",
          fee_value: "",
          fine_code: "",
          fine_date: "",
          fine_value: "",
          bank: "",
          agency,
          agency_d,
          account,
          account_d,
          agency_account: "",
        });
      });

      console.log("Ticket data", data);

      this.$refs.createBankTicket.open();

      const response = await this.$store.dispatch(
        "checkout/generate_bank_ticket",
        this.invoiceId
      );
      if (response) {
        this.submited = true;
      }
      // const response = await this.$store.dispatch(
      //   "checkout/generate_bank_ticket",
      //   { data, bank_gateway: this.bank_gateway }
      // );

      // console.log("RESPONSE AFTER GENERATE", response);

      // this.freezeButton = false;
    },
  },
  mounted() {
    this.bank_gateway = this.unityData.url_gateway;
  },
};
</script>
